<template>
  <div>
        <headerother titleurl="homepage" ref="header"></headerother>
    <div class="center">
      <div class="title">网上地图</div>
      <div class="boxView">
        <div class="boxTitle">网上地图</div>
        <div class="tabsBox">
          <div class="tabsboxTitle">
            <span>服务类</span>
          </div>
          <div class="tabsboxMenu">
            <div class="tabsboxMenuItem">
              <span>评估</span>
              <a href="#javascript">房产</a>
              <a href="#javascript">土地</a>
            </div>
            <div class="tabsboxMenuItem">
              <span>定制加工</span>
              <a href="#javascript">服装定制</a>
            </div>
          </div>
        </div>
        <div class="tabsBox">
          <div class="tabsboxTitle">
            <span>商品类</span>
          </div>
          <div class="tabsboxMenu">
            <div class="tabsboxMenuItem">
              <span>茶 酒</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../../components/footer.vue';
import Headerother from '../../components/headerother.vue';
export default {
  components: { Footer, Headerother },
  data() {
    return {};
  },
};
</script>

<style scoped>
.center {
  width: 1140px;
  margin: 0 auto;
}
.center .title {
  margin: 10px;
}
.center .boxView {
  padding: 80px;
  width: 920px;
  height: 475px;
  background-color: #fff;
}
.center .boxTitle {
  text-align: center;
  font-size: 30px;
  margin-bottom: 40px;
}
.center .tabsBox {
  width: 920px;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 20px;
}
.center .tabsBox .tabsboxTitle {
  height: 50px;
  background-color: #f5f5f5;
  line-height: 50px;
  font-size: 24px;
}
.center .tabsBox .tabsboxTitle span {
  margin-left: 20px;
}
.tabsBox .tabsboxMenu .tabsboxMenuItem {
  margin-bottom: 10px;
}
.tabsBox .tabsboxMenu .tabsboxMenuItem span:nth-of-type(1) {
  font-size: 20px;
  margin-left: 30px;
}
.tabsBox .tabsboxMenu .tabsboxMenuItem a:nth-of-type(1) {
  font-size: 18px;
  margin-left: 50px;
  color: #838383;
}
.tabsBox .tabsboxMenu .tabsboxMenuItem a:nth-of-type(2) {
  font-size: 18px;
  margin-left: 20px;
  color: #838383;
}
</style>